/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppTrackerService {
  private get _paq(): any {
    return (window as any)._paq;
  }

  canTrack(): boolean {
    return !(typeof this._paq === 'undefined' || this._paq === null);
  }

  setUserId(userId: string): void {
    if (this.canTrack()) {
      this._paq.push(['setUserId', userId]);
    }
  }

  trackEvent(
    category: string,
    action: string,
    name: string | null = null,
    value: string | null = null,
    dimensions: any = null,
  ): void {
    if (this.canTrack()) {
      if (dimensions) {
        this._paq.push(['trackEvent', category, action, name, value, dimensions]);
      } else if (value) {
        this._paq.push(['trackEvent', category, action, name, value]);
      } else if (name) {
        this._paq.push(['trackEvent', category, action, name]);
      } else {
        this._paq.push(['trackEvent', category, action]);
      }
    }
  }

  setCustomDimensionValue(id: number, value: string): void {
    if (this.canTrack()) {
      this._paq.push(['setCustomDimensionValue', id, value]);
    }
  }

  deleteCustomDimension(id: number): void {
    if (this.canTrack()) {
      this._paq.push(['deleteCustomDimension', id]);
    }
  }

  trackPageView(customPageTitle: string, dimensions: any = null): void {
    if (this.canTrack()) {
      if (dimensions) {
        this._paq.push(['trackPageView', customPageTitle, dimensions]);
      } else {
        this._paq.push(['trackPageView', customPageTitle]);
      }
    }
  }
}
