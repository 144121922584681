import { Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AppRouterTrackerService } from './app-router-tracker.service';
import { LocalService } from './local.service';
import { OneDefaultTranslationsService } from './one-default-translations.service';

export default function AppInitializer(injector: Injector) {
  return (): Promise<void> => {
    return new Promise<void>((resolve) => {
      // it's needed to be injected, otherwise it's not even included in the code because it's not used.
      // Please do not deleted;
      injector.get(AppRouterTrackerService);

      const localService = injector.get(LocalService);
      const translateService = injector.get(TranslateService);
      const defaultTranslationsService = injector.get(OneDefaultTranslationsService);

      const p1 = new Promise<void>((resolve1, _) => {
        translateService.onLangChange.subscribe(() => resolve1());
      });

      // Get the language from local storage and set it in the translate service
      const language = localService.getPortalLanguage() ?? 'en';

      const p2 = defaultTranslationsService.loadDefaultTranslations();
      const p3 = translateService.use(language).toPromise();

      Promise.all([p1, p2, p3]).then((_) => resolve());
    });
  };
}
