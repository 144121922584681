/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UserProfileService } from '@app/one-core/services/user-profile.service';

@Injectable()
export class DataSupplierInterceptor implements HttpInterceptor {
  constructor(private userProfileService: UserProfileService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userTenant = this.userProfileService.getTenant();

    if (userTenant) {
      const newRequest = request.clone({
        headers: request.headers.set('one-datasupplier-id', userTenant.dataSupplierId.toString()),
      });
      return next.handle(newRequest);
    }

    return next.handle(request);
  }
}
