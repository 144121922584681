import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { environment } from '@env/environment';

import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { MessageService } from 'primeng/api';

import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { OneHttpClient, OneHttpClientCreator } from '@app/one-core/common/http/one-httpclient-factory';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './bootstrap/app';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/directives/auth-interceptor';
import { DataSupplierInterceptor } from './core/directives/data-supplier-interceptor';
import { HttpErrorInterceptor } from './core/directives/http-error-interceptor';
import { LanguageInterceptor } from './core/directives/language-interceptor';
import { AppContextService } from './core/services/app-context.service';
import AppInitializer from './core/services/app-initializer';
import { OneMissingTranslationHandler } from './core/services/one-missing-translation-handler';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const oktaAuth = new OktaAuth({
  issuer: `${environment.oktaDomain}/oauth2/default`,
  clientId: environment.oktaClientId,
  redirectUri: `${environment.services.baseUri}/app/login/callback`,
  postLogoutRedirectUri: `${environment.services.baseUri}/app`,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    OktaAuthModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: OneMissingTranslationHandler,
        deps: [Injector],
      },
      useDefaultLang: true,
    }),

    AppRoutingModule,
    CoreModule,
  ],
  providers: [
    {
      provide: OneHttpClient,
      useFactory: OneHttpClientCreator,
      deps: [HttpClient],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataSupplierInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (context: AppContextService) => (): void => context.init(),
      deps: [AppContextService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      deps: [Injector],
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: OneErrorHandlerPrivate
    // },
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth },
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
