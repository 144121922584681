import { Injector } from '@angular/core';

import { environment } from '@env/environment';

import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { OneDefaultTranslationsService } from './one-default-translations.service';

export class OneMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private injector: Injector) {}

  handle(params: MissingTranslationHandlerParams): string {
    const defaultTranslationsService = this.injector.get(OneDefaultTranslationsService);

    if (
      defaultTranslationsService.defaultTranslations &&
      this.getTranslation(defaultTranslationsService.defaultTranslations, params.key) !== undefined
    ) {
      const translation = params.translateService.getParsedResult(
        defaultTranslationsService.defaultTranslations,
        params.key,
        params.interpolateParams,
      );
      return `${this.getPrefix()}${translation}`;
    } else {
      return `${this.getPrefix()}${params.key}`;
    }
  }

  // get deep level of property of an object eg: getTranslation({a: {b: 2}}, 'a.b') => 2
  private getTranslation(obj, keys: string): string {
    return keys?.split('.').reduce((cur, key) => (cur == undefined ? undefined : cur[key]), obj);
  }

  private getPrefix(): string {
    const environmentLabel = environment.environmentLabel.toLowerCase();
    return environmentLabel.startsWith('stg') || environmentLabel.startsWith('prod') ? '' : '*** ';
  }
}
