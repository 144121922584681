/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpInterceptor,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';

import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

import { TranslateService } from '@ngx-translate/core';

import { ActionMenuService } from '@app/one-core/services/action-menu.service';
import {
  HttpBaseResponse,
  HttpBaseResponseStatus,
  OneApiResponseErrorCode,
} from '@app/one-core/common/http/http-base-response';
import { MessageHelperService } from '@app/one-core/services/message-helper.service';
import { OneHttpParams } from '@app/one-core/common/http/one-httpclient-factory';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private unauthorizedHandle$ = new Subject<void>();

  constructor(
    private actionMenuService: ActionMenuService,
    private messageHelperService: MessageHelperService,
    private translateService: TranslateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {
    // Use debounceTime to skip the error
    // [okta-auth-sdk] WARN: a saved auth transaction exists in storage.
    // This may indicate another auth flow is already in progress.
    this.unauthorizedHandle$.pipe(debounceTime(2500)).subscribe(() => {
      this.oktaAuth.signInWithRedirect().then();
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((response: HttpResponse<HttpBaseResponse<any>>) => {
        if (response instanceof HttpResponse) {
          const res = response.body as HttpBaseResponse<any>;
          if (res?.status === HttpBaseResponseStatus.Error && !(request.params as OneHttpParams)?.silenceAction) {
            let errorMessage = '';
            if (res.errors.length === 0) {
              errorMessage = this.translateService.instant('errors.unknown');
            } else if (res.errors[0].errorCode === OneApiResponseErrorCode.oneIsUnderMaintenance) {
              window.location.href = '/app/assets/static/maintenance/index.html';
            } else {
              errorMessage = this.translateService.instant(`errors.${res.errors[0].errorCode}`);
            }

            this.messageHelperService.showError(errorMessage);
            this.actionMenuService.setLoading(false);
          }
        }

        return response;
      }),
      debounceTime(250),
      catchError((error: HttpErrorResponse) => {
        switch (error?.status) {
          case 401:
            this.unauthorizedHandle$.next();
            // To skip the error
            // caught TypeError: You provided 'undefined' where a stream was expected.
            // You can provide an Observable, Promise, ReadableStream, Array, AsyncIterable, or Iterable.
            // We throw an exception because we are redirecting to login page
            throw error.message;
          case 503:
            window.location.href = '/maintenance';
            return of();
          case 504:
            this.messageHelperService.showError('errors.' + error.status);
            return of();
          default:
            // Handle other kind of errors like 401, 404, 500
            if (!(request.params as OneHttpParams)?.silenceAction) {
              this.messageHelperService.showError(this.translateService.instant('errors.unknown'));
            }
            throw error;
        }
      }),
    );
  }
}
