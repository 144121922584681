import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { AppTrackerService } from './app-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class AppRouterTrackerService {
  constructor(router: Router, tracker: AppTrackerService) {
    router.events
      .pipe(
        // only include NavigationStart and NavigationEnd events
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        let segments = router.getCurrentNavigation().finalUrl?.root.children?.['primary']?.segments;
        if (!segments?.length) {
          segments = router.parseUrl(event.urlAfterRedirects).root.children?.['primary']?.segments;
        }

        if (segments?.length) {
          let url = '';
          segments.forEach((s) => (url += `/${s.path.match(/^\d+$/) !== null ? ':id' : s.path}`));
          tracker.trackPageView(url);
        }
      });
  }
}
